<template>
    <button :class="{grey: !code_flag}" type="button" @click="getPhoneCode">{{msg}}</button>
</template>

<script>
export default {
  name: 'phone-code',
  props: {
    'mobile': {
      type: String
    },
    'url': {
      type: String
    }
  },
  data () {
    return {
      msg: '获取验证码',
      count_time: 60,
      code_flag: true
    }
  },
  methods: {
    getPhoneCode: function () {
      if (this.code_flag) {
        let url = this.url
        if (!this.mobile) {
          this.$layer.msg('请输入手机号')
          return true
        }
        let param = {
          mobile: this.mobile,
          type: 1
        }
        this.$post(url, param).then(res => {
          this.$layer.msg(res.msg)
          if (res.code === 20000) {
            this.countDown()
          }
        }).catch(response => {
          this.$layer.msg('网络错误，请稍候再试！')
        })
      }
    },
    countDown: function () {
      this.code_flag = false
      this.msg = `重新获取(${this.count_time})`
      let t = setInterval(() => {
        if (this.count_time > 0) {
          this.count_time--
          this.msg = `重新获取(${this.count_time})`
        } else {
          this.msg = '获取验证码'
          this.count_time = 60
          this.code_flag = true
          window.clearInterval(t)
        }
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.grey{
  color: #999 !important;
}
</style>
