<template>
  <div class="login" id="login">
    <div class="logo-top">
      <div class="img">
        <router-link to="/">
          <img src="../assets/img/xinli_logo.png">
          <img src="../assets/img/logo.jpg">
        </router-link>
      </div>
      <div class="name">多模态儿童身心健康成长规划系统</div>
    </div>
    <div class="login-shade">
      <div class="font">
        <img src="../assets/img/img_notebook.png">
      </div>
      <div class="login-box">
        <form action="/api/login" method="post">
          <h1>注册账号</h1>
          <label>手机号码</label>
          <div class="input-box">
            <span class="icon-phone"></span>
            <input type="tel" placeholder="请输入手机号" autocomplete="off"
              v-model="formData.phone" >
          </div>
          <label>手机验证码</label>
          <div class="input-box">
            <span class="icon-code"></span>
            <input class="code-input" type="text" placeholder="请输入验证码" autocomplete="off"
              v-model="formData.phone_code">
            <code-btn class="code-btn" :mobile="formData.phone" :url="codeUrl"></code-btn>
          </div>
          <label>密码</label>
          <div class="input-box">
            <span class="icon-lock"></span>
            <input type="password" placeholder="请输入密码" autocomplete="off"
              v-model="formData.password">
          </div>
          <label>确认密码</label>
          <div class="input-box">
            <span class="icon-lock"></span>
            <input type="password" placeholder="请再次输入密码" autocomplete="off"
              v-model="formData.re_password">
          </div>
          <div class="button-box">
            <button type="button" @click="regSubmit">立即注册</button>
          </div>
          <div class="to-home"><router-link to="/login">去登录&gt;&gt;</router-link></div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import CodeBtn from '@/components/CodeBtn'

export default {
  name: '',
  data () {
    return {
      formData: {
        phone: '',
        phone_code: '',
        password: '',
        re_password: ''
      },
      codeUrl: 'login/sendmsg'
    }
  },
  mounted: function () {
    var div = document.getElementById('login')
    var h = document.documentElement.clientHeight || document.body.clientHeight
    div.style.minHeight = h + 'px'
  },
  components: {
    CodeBtn
  },
  methods: {
    regSubmit: function () {
      if (!this.formData.phone) {
        this.$layer.msg('请输入手机号码！')
        return true
      }
      let phone = /^((13|14|15|17|18)[0-9]{1}\d{8})$/
      if (!phone.test(this.formData.phone)) {
        this.$layer.msg('您输入的手机号码不正确，请重新输入！')
        return true
      }
      if (!this.formData.phone_code) {
        this.$layer.msg('请输入验证码！')
        return true
      }
      if (!this.formData.password) {
        this.$layer.msg('请输入密码！')
        return true
      }
      let psw = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,21}$/
      if (!psw.test(this.formData.password)) {
        this.$layer.msg('密码格式不正确，由6-21字母和数字组成，不能为纯数字和纯字母！')
        return true
      }
      if (this.formData.password !== this.formData.re_password) {
        this.$layer.msg('两次密码输入不一致，请重新输入！')
        return true
      }
      let params = this.formData
      this.$post('login/register', params).then(res => {
        this.$layer.msg(res.msg)
        if (res.code === 20000) {
          localStorage.setItem('token', res.data)
          setTimeout(() => {
            this.$router.push('/user')
          }, 500)
        }
      }).catch(response => {
        this.$layer.msg('网络异常，请稍后重试！')
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../assets/css/page/login');
</style>
